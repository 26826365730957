import { Service } from "../classes/service";
import { FileModel } from "./file";

export enum ArrangementStatus {
  Active = 'active',
  Invoiced = 'invoiced',
  Archived = 'archived',
  Closed = 'closed',
  Quote = 'quote',
  CurrentLead = 'current-lead',
  LostLead = 'lost-lead',
  ReadyToReconcile = 'ready-to-reconcile',
  PendingDeath = 'pending-death',
  Testing = 'testing',
}

export enum ArrangementType {
  PreNeed = 'pre-need',
  PrePaid = 'pre-paid',
  AtNeed = 'at-need',
}

export enum NameTitle {
  Mr = 'mr',
  Ms = 'ms',
  Mrs = 'mrs',
  Miss = 'miss',
  Dr = 'dr',
  Professor = 'professor',
  Master = 'master',
  Father = 'father',
  Sir = 'sir',
  Lady = 'lady',
}

export enum DateTimeType {
  Date = 'date',
  Time = 'time',
  DateTime = 'date-time',
}

export enum TimelineType {
  Process = 'process',
  Event = 'event',
}

export enum TimelineStatus {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Undefined = 'undefined',
}

export enum RelationToDeceased {
  Unknown = 'Unknown',

  Wife = 'Wife',
  Husband = 'Husband',

  NextOfKin = 'Next-of-kin',
  Celebrant = 'Celebrant',
  Supplier = 'Supplier',
  FirstCallRecipient = 'First-Call-Recipient',
  FirstCallContact = 'First-Call-Contact',
  ReportingDoctor = 'Reporting-Doctor',

  Father = 'Father',
  Mother = 'Mother',
  Parent = 'Parent',
  Agent = 'Agent',
  Aunt = 'Aunt',
  Brother = 'Brother',
  BrotherInLaw = 'Brother-in-Law',
  Caseworker = 'Caseworker',
  Cousin = 'Cousin',
  Daughter = 'Daughter',
  DaughterInLaw = 'Daughter-in-Law',
  DeFacto = 'De facto',
  DirectorOfNursing = 'Director of Nursing',
  Executor = 'Executor',
  Executrix = 'Executrix',
  ExSpouse = 'Ex-Spouse',
  FatherInLaw = 'Father-in-Law',
  FosterFather = 'Foster Father',
  FosterMother = 'Foster Mother',
  Friend = 'Friend',
  FuneralDirector = 'Funeral Director',
  Goddaughter = 'Goddaughter',
  Godfather = 'Godfather',
  Godmother = 'Godmother',
  Godson = 'Godson',
  Granddaughter = 'Granddaughter',
  Grandfather = 'Grandfather',
  Grandmother = 'Grandmother',
  Grandnephew = 'Grandnephew',
  Grandniece = 'Grandniece',
  Grandson = 'Grandson',
  GreatAunt = 'Great-Aunt',
  GreatGranddaughter = 'Great-Granddaughter',
  GreatGrandson = 'Great-Grandson',
  GreatUncle = 'Great-Uncle',
  Guardian = 'Guardian',
  HalfBrother = 'Half Brother',
  HalfSister = 'Half Sister',
  Informant = 'Informant',
  MotherInLaw = 'Mother-in-Law',
  Nephew = 'Nephew',
  Niece = 'Niece',
  NoRelation = 'No Relation',
  Parents = 'Parents',
  SecondCousin = 'Second Cousin',
  Self = 'Self',
  Sister = 'Sister',
  SisterInLaw = 'Sister-in-Law',
  Son = 'Son',
  SonInLaw = 'Son-in-Law',
  StepBrother = 'Step Brother',
  StepDaughter = 'Step Daughter',
  StepFather = 'Step Father',
  StepSister = 'Step Sister',
  StepSon = 'Step Son',
  StepMother = 'Step Mother',
  ThirdCousin = 'Third Cousin',
  Uncle = 'Uncle',
  Widow = 'Widow',
  Widower = 'Widower',
}

export enum ArrangementMeetingType {
  InPerson = 'in-person',
  TeleConference = 'teleconference',
  VideoCall = 'video-call'
}

export enum EmailType {
  Primary = 'primary',
  Secondary = 'secondary',
  Work = 'work',
  Other = 'other',
}

export enum PhoneType {
  Primary = 'primary',
  Secondary = 'secondary',
  Home = 'home',
  Mobile = 'mobile',
  Work = 'work',
  Other = 'other',
}

export enum DateOfPassingType {
  On = 'on',
  Between = 'between',
  Unknown = 'unknown',
  Approx = 'approx',
  OnOrAfter = 'on-or-after',
  OnOrAbout = 'on-or-about',
  SometimeOnOrAfter = 'sometime-on-or-after',
}

export enum Gender {
  Female = 'Female',
  Indeterminate = 'Indeterminate',
  Intersex = 'Intersex',
  Male = 'Male',
  Unknown = 'Unknown',
  NonSpecific = 'Non-specific',
}

export enum Source {
  Internet = 'internet',
  GoogleAd = 'google-ad',
}

export enum LeadDriver {
  Brand = 'brand',
  Charity = 'charity',
  Cost = 'cost',
}

export enum LeadObjection {
  Brand = 'brand',
  Competitor = 'competitor',
  Cost = 'cost',
}

export enum TaskStatus {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Undefined = 'undefined',
}

export enum ServiceVisibility {
  All = 'all',
  Account = 'account',
  Self = 'self',
}

export interface User {
  id?: number;
  [key: string]: any;
} // Need to define this. Also need to make sure it can be used as a Contact

export interface ArrangementRequest {
  [key: string]: any;
};

export interface ArrangementResponse {
  data: {
    [key: string]: any;
  }
};

export interface ArrangementUpdateResponse {
  data: {
    created: number;
    updated: number;
    deleted: number;
    arrangement: { [key: string]: any; };
  };
};

export interface ContactResponse {
  data: {
    [key: string]: any;
  }
};

export interface Arrangement {
  referenceId?: string;
  status: ArrangementStatus;
  type: ArrangementType;
  managedByCoroner: boolean;
  displayServiceFee: boolean;
  reporting?: Reporting;
  deceased?: Deceased;
  firstCall?: FirstCall;
  planningSession?: PlanningSession;
  transfers?: Transfer[];
  contacts?: Contact[];
  timeline?: Timeline[];
  tasks?: any[];
  notes?: Note[];
  ceremonies?: Ceremony[];
}

export interface ArrangementDocument {
  id: string;
  accountId: string;
  arrangementId: string;
  userId: string;
  isDeleted: number;
  fileId: string;
  _file?: FileModel;
}

export interface Ceremony {
  date: DateTime;
  duration: number;
  
}

export interface Deceased {
  name: Name;
  gender: Gender|string;
  dateOfBirth: DateTime;
  dateOfPassing: DateOfPassing;
  placeOfPassing: Address;
  isStillBorn: boolean;
  relationships?: []
}

export interface Name {
  title?: NameTitle;
  first: string;
  middle?: string;
  last: string;
  lastNameAtBirth?: string;
  aka?: string;
}

export interface DateTime {
  type: DateTimeType;
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
  offset?: number;
}

export interface Address {
  street?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  country?: string;
}

export interface Reporting {
  source: Source|string;
  lead: {
    driver: LeadDriver;
    objection: LeadObjection;
  }
}

export interface Timeline {
  id: number;
  type: TimelineType;
  status: TimelineStatus;
  style: {
    color: string;
    icon: string;
  };
  title: string;
  referencePath?: string;
  created: DateTime;
  place?: Place[];
  tasks?: Task[];
}

export interface Place {
  date?: Date;
  dateReferencePath?: string;
  address?: Address;
  addressReferencePath?: string;
}

export interface DateOfPassing {
  type: DateOfPassingType;
  date?: DateTime;
  from?: DateTime;
  to?: DateTime;
}

export interface Contact {
  _meta?: { id: string, index: number; deleted?: boolean; };
  id: string;
  name: Name;
  address?: Address;
  mailingAddress?: Address;
  mailingAddressSameAsAddress?: boolean;
  email?: { primary?: string; secondary?: string };
  phone?: { primary?: string; secondary?: string };
  age?: number;
  dateOfBirth?: DateTime;
  gender?: Gender;
  // relationToDeceased?: (RelationToDeceased|string)[] // Commented out by Ivan - 2023-12-17 - 
  relationToDeceased?: { name: string, value: string }[];
}

export interface Email {
  type: (EmailType|string)[];
  email: string;
}

export interface Phone {
  type: (PhoneType|string)[];
  phone: string;
}

export interface Transfer {
  _meta: { id: string, index: number; deleted?: boolean; };
  service: Service;
  from: {
    date: DateTime;
    address: Address;
    name: Name;
    phone: Phone;
  };
  to: {
    date: DateTime;
    address: Address;
    name: Name;
    phone: Phone;
  };
  isAuthorityToCollectTheDeceasedFormRequired: boolean;
  isWeekday: boolean;
  isOutOfArea: boolean;
  isBespokeCost: boolean;
  cost: Financial
}

export interface Financial {
  id?: number;
  dollars: number;
  cents: number;
  currency?: string;
  incGST?: boolean;
  isEditable?: boolean;
}

export interface Note {
  userId?: number;
  referencePath: string;
  createdAt: Date;
  text: string;
  snooze?: Snooze;
}

export interface Snooze {
  date: DateTime;
}

export interface PlanningSession {
  celebrant?: Contact;
  clergy?: Contact;
  clergyCost?: Financial;
  address?: Address;
  date: DateTime;
  isVideoConference: boolean;
  isPhoneConference: boolean;
}

export interface FirstCall {
  source: any;
  recipient: User;
  date: DateTime;
  informant: Contact;
  sourceGroup: any;
}

export interface Task {
  id?: number;
  title: string;
  description: string;
  status: TaskStatus;
  notes: TaskNote[];
}

export interface TaskNote {
  text: string;
  createdAt: DateTime;
  data: string[];
  user?: User;
}